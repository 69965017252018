import { useIsSSR } from "eddev/routing"
import { useEffect, useState } from "react"

export function useIsMobile(maxSize = 800) {
  const hydrating = useIsSSR()
  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window === "undefined") return false
    return window.innerWidth < maxSize
  })

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < maxSize)
    }
    checkMobile()
    window.addEventListener("resize", checkMobile)
    return () => window.removeEventListener("resize", checkMobile)
  }, [])

  return hydrating ? false : isMobile
}
